<template>
  <v-footer id="footerApp" class="p-0 container">
    <div class="footerAll w-100">
      <div class="menuFooter py-2">
        <div class="d-flex mt-3">
          <div class="mr-1" style="width:225px;padding-top: 5px;">
            <v-text-field hide-details class="vTextFieldCustom" outlined dense placeholder="Email"></v-text-field>
          </div>
          <div class="ml-1">
            <v-btn class="d-inline-block" color="#5c90da" outlined>Essayer gratuitement</v-btn>
            <div class="noteBtn"><p class="d-inline-block mr-2">&#128226;</p> Aucune carte de crédit requise </div>
          </div>
          <v-spacer></v-spacer>
        </div>
      </div>
      <div class="d-flex py-2 mb-9 signFooter">
        <div class="w-100">
          <h5>Liens rapide</h5>
          <div class="mt-5 justify-content-between">
            <div class="linkBoxF"><a class="linkFooter" @click="goToPath('products')">Produits</a></div>
            <div class="linkBoxF"><a class="linkFooter" @click="goToPath('pricing')">Prix</a></div>
          </div>
        </div>
        <div class="w-100">
          <h5>Liens rapide</h5>
          <div class="mt-5 justify-content-between">
            <div class="linkBoxF"><a class="linkFooter" @click="goToPath('products')">À propos de nous</a></div>
            <div class="linkBoxF"><a class="linkFooter" @click="goToPath('products')">RGPD</a></div>
            <div class="linkBoxF"><a class="linkFooter" @click="goToPath('products')">Terms of Service</a></div>
            <div class="linkBoxF"><a class="linkFooter" @click="goToPath('products')">Privacy policy</a></div>
          </div>
        </div>
      </div>

      <div class="ssfooter py-2">
        <v-container class="pl-0 pr-0 d-flex align-items-center">
          <div class="socialLink">
            <div class="iconSocial Link d-inline-block"><a target="_blank" href="https://www.facebook.com/ObjectifCRM"><i class="fa-brands fa-facebook"></i></a></div>
            <div class="iconSocial Link d-inline-block"><a target="_blank" href="https://www.linkedin.com/company/objectifcrm/"><i class="fa-brands fa-linkedin"></i></a></div>
          </div>
          <v-spacer/>
          <div class="rowLink">
            <a class="linkFooter mr-2" >Terms of Service</a>
            <a class="linkFooter mr-2" >Privacy policy</a>
          </div>
          <v-spacer/>
          <div class="py-2 reservFooter text-center">@ {{ new Date().getFullYear() }} Objectif CRM. — <strong>Tous droits réservés.</strong></div>
        </v-container>
      </div>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "footerApp",
  props: {
    currentPage: String,
  },
  computed: {
    currentPageSync: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("update:current-page", val);
      }
    }
  },
  methods: {
    goToPath(path) {
      this.$router.push({ name: path});

    },
    loadPage(page) {
      this.currentPageSync = page;
    }
  }
}
</script>

<style scoped>

</style>