<template>
  <div id="downBar" class="top-0 w-100" style="background-color: white!important;z-index: 999;">

    <v-app-bar color="white" style="box-shadow: none">
      <div class="downBar">
        <v-container>
          <div class="d-flex">
            <div @click="goToPath('home')" class="h-100">
              <v-img width="184" style="margin-top: -4px;" class="logo" src="https://www.objectif-crm.com/assets/images/logoOCRMS.png"></v-img>
            </div>
            <div  class="d-none d-sm-inline-block" id="header">
            <!--Produits-->
              <v-btn text @click="goToPath('products')" class="mx-2">Produits</v-btn>
              <v-btn text @click="goToPath('aboutUs')" class="mx-2">Qui sommes nous</v-btn>
              <v-btn text @click="goToPath('pricing')" class="mx-2">Prix</v-btn>
            </div>
            <!--mobil-->
            <v-menu  v-model="menu2" :close-on-content-click="false" :nudge-width="1800" offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="menu2 = true" class="d-sm-none d-inline-block"><i class="fa-solid fa-shield"></i></v-btn>
              </template>
              <v-card class="moreOptionH">
                <div>
                  <div><v-btn text @click="goToPath('products')" class="mx-2">Produits</v-btn></div>
                  <div><v-btn text @click="goToPath('aboutUs')" class="mx-2">Qui sommes nous</v-btn></div>
                  <div><v-btn text @click="goToPath('pricing')" class="mx-2">Prix</v-btn></div>
                </div>
              </v-card>
            </v-menu>
            <!--mobil fin-->

            <v-spacer></v-spacer>
            <v-btn color="#4D1CC5" @click="goToPath('contact')"  outlined class="mx-2 mt-2">
              <span class="d-none d-sm-inline-block">Nous contacter</span>
              <span class="d-sm-none d-inline-block"><i class="fa-solid fa-shield"></i></span>
            </v-btn>
          </div>
        </v-container>
      </div>
    </v-app-bar>
    <div class="HiBar">
      <v-container class=" pb-0" style="padding-top: 3px !important;">
        <div class="d-flex">
          <div class="btbTopH">
            <v-btn text class="mt-0 mr-1 btnConnect" x-small>Inscription</v-btn>
<!--            <v-btn text class="mt-0 mr-1 btnConnect" x-small>Essai gratuit</v-btn>-->
          </div>
          <v-spacer></v-spacer>
          <v-btn text class="mt-1 btnConnect" x-small href="https://login.crm-energie.fr/" target="_blank">Se connecter</v-btn>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerApp",
  data: () => ({
    fav: true,
    menu: false,
    menu1: false,
    menu2: false,
    message: false,
    hints: true,
  }),

  methods: {
    goToPath(path) {
      this.$router.push({ name: path});

    },
    sectionSelect(val){
     console.log(val)
      setTimeout(()=>  this.$root.$emit("sectionShow",val),100);

    }
  }
}
</script>


<style scoped>

</style>