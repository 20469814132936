import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

export const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('../components/HelloWorld')
        },
        {
            path: '/support',
            name: 'support',
            component: () => import('../components/pages/supportApp')
        },

        {
            path: '/produits',
            name: 'products',
            component: () => import('../components/pages/produitApp')
        },
        {
            path: '/fonctionalite',
            name: 'fonctionalite',
            component: () => import('../components/pages/fonctionApp')
        },
        {
            path: '/services',
            name: 'services',
            component: () => import('../components/pages/servicesApp')
        },
        {
            path: '/pricing',
            name: 'pricing',
            component: () => import('../components/pages/pricingApp')
        },
        {
            path: '/contact',
            name: 'contact',
            component: () => import('../components/pages/contactApp')
        },
        {
            path: '/aboutUs',
            name: 'aboutUs',
            component: () => import('../components/pages/aboutUs')
        },

    ]

});

