<template>
  <v-app>
    <header-app/>
    <router-view/>
    <footer-app/>
  </v-app>
</template>

<script>

import HeaderApp from "@/components/layout/headerApp";
import FooterApp from "@/components/layout/footerApp";

export default {
  name: 'App',

  components: {
    FooterApp,
    HeaderApp,

  },

  data: () => ({
    //
  }),
};
</script>
